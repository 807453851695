import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const AboutUSStyle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 20px;
    .about-head {
        width: 100%;
        max-width: 840px;
        img { width: 100%; }
        .head-article {
            width: 100%;
            padding: 20px;
            margin: 40px auto 60px;
            h1 {
                color: var(--blue);
                font-weight: bold;
                margin-bottom: 10px;
            }
            h2 span {
                color: var(--blue);
                font-weight: bold;
            }
            p {
                margin-top: 20px;
            }
        }
    }
    .co-pros {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            margin-bottom: 40px;
            font-weight: bold;
        }
        .pros {
            width: 100%;
            max-width: 860px;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            .pro {
                width: 50%;
                padding: 30px;
                .pro-text {
                    font-size: 20px;
                    font-weight: bold;
                    margin-top: 5px;
                }
                p {
                    color: var(--dark);
                    margin-top: 5px;
                }
                .pro-icon {
                    width: 80px;
                    height: 80px;
                }
                :nth-child(1) .pro-icon {
                    background: white url(https://mtimg.onemiao.cn/web-images/about/about_icon1.svg) center no-repeat;
                    background-size: 80px;
                }
                :nth-child(2) .pro-icon {
                    background: white url(https://mtimg.onemiao.cn/web-images/about/about_icon2.svg) center no-repeat;
                    background-size: 80px;
                }
                :nth-child(3) .pro-icon {
                    background: white url(https://mtimg.onemiao.cn/web-images/about/about_icon3.svg) center no-repeat;
                    background-size: 80px;
                }
                :nth-child(4) .pro-icon {
                    background: white url(https://mtimg.onemiao.cn/web-images/about/about_icon4.svg) center no-repeat;
                    background-size: 80px;
                }
            }
        }
    }
    .our-certificates {
        width: 100%;
        padding-top: 100px;
        h2 {
            font-weight: bold;
            text-align: center;
        }
        .certificates {
            width: 100%;
            margin: 40px auto 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            .certificate {
                width: 260px;
                padding: 20px;
                img { width: 100%; }
                ::nth-child(3) {
                    width: 210px;
                }
            }
        }
    }
    .our-env {
        width: 100%;
        padding-top: 100px;
        h2 {
            font-weight: bold;
            text-align: center;
        }
        .env-pics {
            width: 100%;
            margin: 40px auto 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            .env-pic {
                width: 420px;
                height: 300px;
                padding: 10px;
                img { 
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                 }
            }
        }
    }
    .about-contact {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 20px 60px;
        h2 {
            font-weight: bold;
        }
        p {
            color: var(--dark);
            margin: 20px 0 10px;
        }
        .contacts-row {
            margin-top: 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: stretch;
            .contact-info {
                width: 200px;
                padding: 10px;
                display: flex;
                align-items: stretch;
                .contact-card {
                    display: block;
                    width: 100%;
                    border-radius: 10px;
                    padding-top: 10px;
                    border: 1px solid var(--lightGray);
                    .contact-head {
                        width: 100%;
                        padding: 5px 0 5px 60px;
                        .contact-text {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            span {
                                font-size: 12px;
                                color: var(--gray);
                            }
                        }
                    }
                    .qrcode {
                        width: 100%;
                        img { width: 100%; }
                    }
                }
                .contact-card.xhs .contact-head {
                    background: url("https://mtimg.onemiao.cn/web-images/about/contact_xhs.png") left 10px center no-repeat;
                    background-size: 50px;
                }
                .contact-card.weibo .contact-head {
                    background: url("https://mtimg.onemiao.cn/web-images/about/contact_weibo.png") left 15px center no-repeat;
                    background-size: 50px;
                }
                .contact-card.wechat .contact-head {
                    background: url("https://mtimg.onemiao.cn/web-images/about/contact_wechat.png") left 18px center no-repeat;
                    background-size: 50px;
                }
                .contact-card.wechat-co { 
                    .contact-head {
                        background: url("https://mtimg.onemiao.cn/web-images/about/contact_wechat_co.png") left 10px center no-repeat;
                        background-size: 65px;
                    }
                    .qrcode {
                        padding: 8px;
                    }
                }
            }
            .contact-info.hide {
                display: none;
            }
        }
    }
`

export default function AboutUS() {
    return <AboutUSStyle>
        <div className="about-head">
            <img src="https://mtimg.onemiao.cn/web-images/about/about_head.png" alt="" />
            <div className="head-article">
                <h1>北京安通康泰健康科技有限公司</h1>
                <h2>专注为生物制药及医疗服务领域提供<br />基于<span>SaaS云解决方案</span>的创新者及引导者</h2>
                <p>北京安通康泰健康科技有限公司成立于2018年，凭借对中国疫苗行业的前瞻性专业视角和基于数智驱动的数字化技术能力，满足疫苗推广企业降本增效的核心诉求，提高其精细化及合规运营水平，并沉淀具有长远价值的数据资产，成为生物制药（疫苗）推广领域企业的数字化合作伙伴，助力其降本增效与合规转型。</p>
                <p>安通围绕疫苗推广行业业务全流程，自主研发了“苗通CRM”、“苗通UTS”、“苗通EDU”等数字化推广与管理平台，并提供多方面的增值服务组合，在企业管理、业绩提升、合规运营等方面为企业助力，成为众多推广企业业务转型升级中不可或缺的一环。</p>
            </div>
        </div>
        <div className="co-pros">
            <h2>合作优势</h2>
            <div className="pros">
                <div className="pro">
                    <div className="pro-icon"></div>
                    <div className="pro-text">权威保障</div>
                    <p>安通康泰积极投身公益事业，是多家国家级5A及4A公益基金会的优质合作伙伴。</p>
                </div>
                <div className="pro">
                    <div className="pro-icon"></div>
                    <div className="pro-text">专业保障</div>
                    <p>核心团队在政府公共卫生领域、大健康领域以及互联网科技领域从业多年，拥有丰富的专业经验。</p>
                </div>
                <div className="pro">
                    <div className="pro-icon"></div>
                    <div className="pro-text">品质保障</div>
                    <p>安通康泰以客户为中心，以国家健康战略和行动方案评价体系为依托，为个人和企业提供高标准严要求的品质服务。</p>
                </div>
                <div className="pro">
                    <div className="pro-icon"></div>
                    <div className="pro-text">数据保障</div>
                    <p>安通康泰作为高新技术企业，拥有完备的数据安全保护体系与技术能力，保障系统数据安全。</p>
                </div>
            </div>
        </div>
        <div className="our-certificates">
            <h2>公司资质</h2>
            <div className="certificates">
                <div className="certificate"><img src="https://mtimg.onemiao.cn/web-images/about/about_cer1.png" alt="" /></div>
                <div className="certificate"><img src="https://mtimg.onemiao.cn/web-images/about/about_cer2.png" alt="" /></div>
                <div className="certificate"><img src="https://mtimg.onemiao.cn/web-images/about/about_cer4.png" alt="" /></div>
                <div className="certificate"><img src="https://mtimg.onemiao.cn/web-images/about/about_cer3.png" alt="" /></div>
            </div>
        </div>
        <div className="our-env">
            <h2>公司环境</h2>
            <div className="env-pics">
                <div className="env-pic"><img src="https://mtimg.onemiao.cn/web-images/about/about_pic1.png" alt="" /></div>
                {/* <div className="env-pic"><img src="https://mtimg.onemiao.cn/web-images/about/about_pic2.png" alt="" /></div> */}
                <div className="env-pic"><img src="https://mtimg.onemiao.cn/web-images/about/about_pic3.png" alt="" /></div>
            </div>
        </div>
        <div className="about-contact" id="contact">
            <h2>联系我们</h2>
            <p>如有任何问题请联系我们，我们竭诚为您服务</p>
            <h2>400-810-9830</h2>
            <h2>010-64022850</h2>
            <div className="contacts-row">
                <div className="contact-info hide">
                    <Link to="/about" className="contact-card xhs">
                        <div className="contact-head">
                            <div className="contact-text">
                                <span>欢迎关注我们</span>
                                <h3>一苗预约</h3>
                            </div>
                        </div>
                        <div className="qrcode">
                            <img src="" alt="" />
                        </div>
                    </Link>
                </div>
                <div className="contact-info hide">
                    <Link to="/about" className="contact-card weibo">
                        <div className="contact-head">
                            <div className="contact-text">
                                <span>欢迎关注我们</span>
                                <h3>一苗预约</h3>
                            </div>
                        </div>
                        <div className="qrcode">
                            <img src="" alt="" />
                        </div>
                    </Link>
                </div>
                <div className="contact-info">
                    <Link to="/about#contact" className="contact-card wechat-co">
                        <div className="contact-head">
                            <div className="contact-text">
                                <span>企业微信</span>
                                <h3>苗通</h3>
                            </div>
                        </div>
                        <div className="qrcode">
                            <img src="https://mtimg.onemiao.cn/web-images/wechat_qrcode.jpeg" alt="" />
                        </div>
                    </Link>
                </div>
                <div className="contact-info">
                    <Link to="/about#contact" className="contact-card wechat">
                        <div className="contact-head">
                            <div className="contact-text">
                                <span>微信公众号</span>
                                <h3>苗通</h3>
                            </div>
                        </div>
                        <div className="qrcode">
                            <img src="https://mtimg.onemiao.cn/web-images/wechat_im.jpeg" alt="" />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </AboutUSStyle>
}